import React, { useState, useEffect, useCallback } from 'react';
import { Box } from 'grommet';
import queryString from 'query-string';
import moment from 'moment';

import { COMPANY_EVENTS } from 'pages/shiftManagement/constants';
import { PTO_POLICY_EVENTS } from 'pto/constants';
import COLUMNS from 'pages/employeePaidTimeOff/constants';

import { getPageSizeAndNo, setCookies ,setAuthToken} from 'utils/auth-singleton';
import EventEmitter from 'utils/event-emitter';
import Singleton from 'utils/singleton';

import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
import GenericReactTable from 'pages/components/PTO/GenericReactTable';
import SectionLoaderAtom from 'atoms/SectionLoader';
import Filter from 'pages/employeePaidTimeOff/component/Filter';

import { getBasicSettings } from 'company/controllers/company';
import { fetchPtoRequestList } from 'pto/controllers/pto';

let eventEmitter = new EventEmitter();

const EmployeePaidTimeOff = props => {
  const [requestListData, setRequestListData] = useState([]);

  const [loader, setLoader] = useState(true);
  const [pageAddTimeOff, setPageAddTimeOff] = useState();
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState(moment(new Date()).subtract(1, 'months').format('DD-MM-YYYY'));
  const [endDate, setEndDate] = useState(moment(new Date()).add(30, 'days').format('DD-MM-YYYY'));
  const [loading, setLoading] = useState(false);
  const [empId, setEmpId] = useState(false);
  const [dateFormat, setDateFormat] = useState('');
  const singleton = new Singleton();
  const {
    location: { search },
  } = props;

  const getPtoRequestList = useCallback((statusId,start_date,end_date)=> {
    setLoading(true);
    fetchPtoRequestList(eventEmitter, {
      is_active: true,
      page_size: getPageSizeAndNo('pageSizeEmployeeTimeOff'),
      page: getPageSizeAndNo('pageNoEmployeeTimeOff'),
      paginate: true,
      employee_id: empId,
      start_date: start_date || startDate,
      end_date: end_date || endDate,
      status: statusId || status,
    });
  }, [startDate,endDate,status,empId]);

  const onRowClick = useCallback((state, rowInfo, column, instance) => {
    return {
      onClick: e => {
        if(rowInfo?.original?.policy_type==='PAID_HOLIDAYS') {
          return;
        }
        GenericWindowPostMessage('OPEN_TIME_OFF_REQUEST_SLIDER', {
          empName: rowInfo.original.employee_name,
          policyId: rowInfo.original.id,
        });
      },
    };
  },[])

  useEffect(() => {
    const query = queryString.parse(search);
    if (singleton.oAuthToken === null) {
      singleton.oAuthToken = query.oAuthToken;
    }
    setEmpId(query.employee_id)
    setCookies('pageSizeEmployeeTimeOff', 20);
    setCookies('pageNoEmployeeTimeOff', 0);
    if (query.oAuthToken) {
      getBasicSettings(eventEmitter);
    }
    fetchPtoRequestList(eventEmitter, {
      is_active: true,
      employee_id: query.employee_id,
      page_size: 20,
      page: 1,
      end_date: endDate,
      start_date: startDate,
    });
  }, [search]);

  useEffect(() => {
    window.addEventListener('message', handleRefresh);
    return () => {
      window.removeEventListener('message', handleRefresh);
    };
  }, [startDate,endDate,status,empId]);

  useEffect(function init() {
    const observable = eventEmitter.getObservable();
    let subscription;
    listenObservable();

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case PTO_POLICY_EVENTS.GET_PTO_REQUEST_LIST_SUCCESS:
            setLoading(false);
            setLoader(false);
            setPageAddTimeOff(Math.ceil(event.data.count / getPageSizeAndNo('pageSizeEmployeeTimeOff')));
            setRequestListData(event.data.results);
            break;

          case PTO_POLICY_EVENTS.GET_PTO_REQUEST_LIST_FAILURE:
            setLoader(false);
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.err || 'Something went wrong, Policies are not fetched.',
            });
            break;

          case COMPANY_EVENTS.GET_BASIC_SETTING_SUCCESS:
            setDateFormat(event.data.dateFormat);
            break;

          case COMPANY_EVENTS.GET_BASIC_SETTING_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.err || 'something went wrong',
            });
            break;

          default:
        }
      });
    }

    return () => subscription?.unsubscribe();
  }, []);

  const handleRefresh = useCallback(event => {
      if (event.data === 'REFRESH_EMPLOYEE_TIMEOFF') {
        getPtoRequestList();
      }
    },[startDate,endDate,status,empId]
  )
  
  const applyFilter = useCallback(values => {
    setLoading(true);
    const start_date = moment(values.start_date).format('DD-MM-YYYY');
    const end_date = moment(values.end_date).format('DD-MM-YYYY');
    setStartDate(start_date);
    setEndDate(end_date);
    setCookies('pageNoEmployeeTimeOff', 0);
    getPtoRequestList(status,start_date,end_date)
  },[startDate, endDate, status, empId])
  const onStatusSelect = useCallback(id => {
    setLoading(true);
    setCookies('pageNoEmployeeTimeOff', 0);
    getPtoRequestList(id)
  },[status, startDate, endDate, empId])

  return (
    loader ? <SectionLoaderAtom active /> :
    <Box margin={{ left: 'medium', right: 'small' }} flex={{ grow: 1 }} gap="small">
      <Box width="fit-content">
        <Filter
          dateFormat={dateFormat}
          fetchPtoRequestList={fetchPtoRequestList}
          eventEmitter={eventEmitter}
          setLoading={setLoading}
          applyFilter={applyFilter}
          status={status}
          setStatus={setStatus}
          onStatusSelect={onStatusSelect}
          dates={{startDate, endDate}}
        />
      </Box>
      <Box direction="column" width="fit-content">
        <GenericReactTable
          data={requestListData.length ? requestListData : []}
          loading={loading}
          onRowClick={onRowClick}
          columns={COLUMNS}
          setLoading={setLoading}
          totalNoOfPage={pageAddTimeOff}
          tableName={'EmployeeTimeOff'}
          onFetchData={getPtoRequestList}
        />
      </Box>
    </Box>
  );
};

export default EmployeePaidTimeOff;
